import React from 'react'
import Navbar from '../containers/Navbar'
import './CoreLayout.css'
import { useLocation } from "react-router-dom";

const CoreLayout = ({ children }) => {
  let { pathname } = useLocation();

  if (pathname.includes('/admin')) return children

  return (<div>
    <Navbar />
    <div className="content">
      {children}
    </div>
  </div>)
}

export default CoreLayout
