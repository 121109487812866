import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import './Lawyers.css';

import margerie from '../../static/margerie4.jpg';
import reine from '../../static/Reine3.jpg';
import cordier from '../../static/Cordier3.jpg';
import gagey from '../../static/Gagey3.jpg';
import oukid from '../../static/Oukid.jpg';
import letter from '../../static/letter.svg';
import vccard from '../../static/id.svg';
import back from '../../static/back.svg';

import ContactSection from '../../containers/ContactSection';
import Banner from '../../layouts/Banner';
const blue = '122342';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Lawyers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const { id } = useParams();
  let lang = useQuery().get('lang');

  let src = null;
  let title = null;
  let alt = null;
  let href = null;
  let mail = null;
  let hrefVCF = null;
  let titleVCF = null;
  let column1 = null;
  let column2 = null;
  let subtitle = null;

  if (id === 'Margerie') {
    src = (
      <span>
        <img src={margerie} alt={alt} className="portrait" />
        <br />
      </span>
    );
    title = 'Stanislas de Margerie';
    subtitle = lang === 'fr' || !lang ? 'Associé' : 'Partner';
    alt = 'Margerie';
    href = 'mailto:margerie@pergame-avocats.com';
    mail = 'margerie@pergame-avocats.com';
    hrefVCF = 'https://www.pergame-avocats.com/Margerie.vcf';
    titleVCF = 'Stanislas de Margerie.vcf';
    if (lang === 'fr' || !lang) {
      column1 = (
        <div>
          Stanislas de Margerie est avocat publiciste et intervient principalement en droit des
          contrats, en droit règlementaire, et en droit de l’urbanisme. Il a également développé une
          expertise spécifique en droit de l’Union européenne (libertés de circulation,
          transposition des textes européens en droit national, contentieux devant la Cour de
          justice…).
          <br />
          <br />
          Il a passé les cinq premières années de sa carrière d’avocat à Bruxelles, dans les équipes
          de droit de la concurrence et des marchés publics de grands cabinets anglo-saxons.
          <br />
          <br />
          Stanislas de Margerie a une double formation d’ingénieur et d’avocat. Il est inscrit aux
          barreaux de Paris et New York, et parle couramment l’anglais et l’allemand.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Associé du cabinet Pergame Avocats (depuis 2017)
          <br />
          <br />
          Collaborateur du cabinet Earth Avocats (2014-2017)
          <br />
          <br />
          Collaborateur du cabinet Bird & Bird à Bruxelles (2012-2013)
          <br />
          <br />
          Collaborateur du cabinet Cleary Gottlieb à Bruxelles (2009-2011)
          <br />
          <br />
          Master of Laws (LL.M.) – New York University (2008)
          <br />
          <br />
          Master 2 de droit public économique – Université de Paris 2 (2007)
          <br />
          <br />
          Master 1 de droit public des affaires – Université de Paris 1 (en échange à l’Université
          de Heidelberg) (2006)
          <br />
          <br />
          Diplôme d’ingénieur civil de l’Ecole des Mines de Paris (2005)
        </div>
      );
    }
    if (lang === 'en') {
      column1 = (
        <div>
          Stanislas de Margerie’s practice focuses on public law (notably public contracts),
          regulatory, urban planning, and environmental law. He has developed a strong expertise in
          European Union law (freedom of movement, transposition of EU law into domestic law,
          litigation before the Court of Justice).
          <br />
          <br />
          He spent the first five years of his career in Brussels in the competition and procurement
          departments of US law firm Cleary Gottlieb and UK law firm Bird & Bird.
          <br />
          <br />
          Stanislas holds an LL.M. from New York University School of Law and is admitted to
          practice in France and the United States (New York). He also holds an engineering degree
          from Ecole des Mines de Paris.
          <br />
          <br />
          Stanislas is bilingual in French and English and speaks German fluently.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Pergame Avocats, Partner (since 2017)
          <br />
          <br />
          Earth Avocats, Paris, Associate (2014-2017)
          <br />
          <br />
          Bird & Bird, Brussels, Associate (2012-2013)
          <br />
          <br />
          Cleary Gottlieb, Brussels, Associate (2009-2011)
          <br />
          <br />
          LL.M., New York University School of Law (2008)
          <br />
          <br />
          Master’s Degree in Public Economic Law, Paris-2 University (2007)
          <br />
          <br />
          Master’s Degree in Public Business Law, Heidelberg University (Germany) and Paris-1
          University (2006)
        </div>
      );
    }
  }
  if (id === 'Reine') {
    src = (
      <span>
        <img src={reine} alt={alt} className="portrait" />
        <br />
      </span>
    );
    title = 'Pierre Reine';
    subtitle = lang === 'fr' || !lang ? 'Associé' : 'Partner';
    alt = 'Reine';
    href = 'mailto:reine@pergame-avocats.com';
    mail = 'reine@pergame-avocats.com';
    hrefVCF = 'https://www.pergame-avocats.com/Reine.vcf';
    titleVCF = 'Pierre Reine.vcf';
    if (lang === 'fr' || !lang) {
      column1 = (
        <div>
          Pierre Reine est avocat publiciste et titulaire d’un certificat de spécialisation en droit
          public, mention contrats publics, délivré par le Conseil national des barreaux.
          <br />
          <br />
          Il intervient en particulier en droit de la commande publique et des contrats publics,
          aussi bien en conseil qu’en contentieux.
          <br />
          <br />
          Ancien secrétaire de la Conférence du barreau de Paris, il continue d’intervenir en droit
          pénal sur les délits spécifiques des personnes exerçant une fonction publique (délit de
          favoritisme, prise illégale d’intérêt, etc.).
          <br />
          <br />
          Il dispense très régulièrement des formations professionnelles, ainsi qu’à l’Ecole de
          formation du barreau de Paris.
          <br />
          <br />
          Il a été élu au Conseil National des Barreaux (mandature 2020-2023).
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Certificat de spécialisation en droit public – Conseil national des barreaux (2018)
          <br />
          <br />
          Associé du cabinet Pergame Avocats (depuis 2017)
          <br />
          <br />
          Collaborateur du cabinet Earth avocats (2008-2017)
          <br />
          <br />
          Secrétaire de la conférence du Barreau de Paris (2011)
          <br />
          <br />
          Master of Laws (LL.M.) - London School of Economics (2008)
          <br />
          <br />
          Magistère de droit des activités économiques - Université de Paris 1 (2006)
          <br />
          <br />
          Master 2 de contentieux de droit public - Université de Paris 1 (2006)
        </div>
      );
    }
    if (lang === 'en') {
      column1 = (
        <div>
          Pierre Reine’s practice focuses on public law, with an emphasis on public contracts and
          public contracts litigation.
          <br />
          <br />
          His practice also covers the criminal aspects of procurement law (favouritism, unlawful
          takings of interest).
          <br />
          <br />
          Pierre regularly trains professionals and lawyers in procurement law and litigation. He is
          a member of the Conférence du Barreau, a prestigious peer-elected association of lawyers.
          <br />
          <br />
          Pierre holds an LL.M. from the London School of Economics.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Pergame Avocats, Partner (since 2017)
          <br />
          <br />
          Earth Avocats, Paris, Associate (2014-2017)
          <br />
          <br />
          Member of the Conférence du Barreau (2011)
          <br />
          <br />
          LL.M., London School of Economics (2008)
          <br />
          <br />
          Master’s degree in Economic Law, Paris-1 University (2006)
          <br />
          <br />
          Master’s degree in public litigation, Paris-1 University (2006)
        </div>
      );
    }
  }
  if (id === 'Cordier') {
    src = (
      <span>
        <img src={cordier} alt={alt} className="portrait" />
        <br />
      </span>
    );
    title = 'Clémence Cordier';
    subtitle = lang === 'fr' || !lang ? 'Associée' : 'Partner';
    alt = 'Cordier';
    href = 'mailto:cordier@pergame-avocats.com';
    mail = 'cordier@pergame-avocats.com';
    hrefVCF = 'https://www.pergame-avocats.com/Cordier.vcf';
    titleVCF = 'Clémence Cordier.vcf';
    if (lang === 'fr' || !lang) {
      column1 = (
        <div>
          Clémence Cordier est avocat publiciste et intervient principalement en droit des contrats
          et droit règlementaire. Elle est titulaire du certificat de spécialisation en droit
          public, avec la qualification spécifique en droit des contrats administratifs.
          <br />
          <br />
          Précédemment associée en charge de l’activité contentieuse d’un cabinet de droit public
          des affaires, elle exerce depuis de nombreuses années auprès des juridictions
          principalement administratives mais également judiciaires et dispose ainsi d’une forte
          expertise contentieuse qu’elle met au service de ses clients.
          <br />
          <br />
          Elle conseille également ses clients dans les modes alternatifs de règlement des
          différends, qui tendent à se multiplier et dont le taux de succès est très important. Elle
          a été formée à l’Ecole Internationale des Modes Alternatifs de règlement des litiges
          (EIMA), au sein du Barreau de Paris et dispose également d’un diplôme universitaire de
          Médiateur (IFOMENE, ICP).
          <br />
          <br />
          Elle dispense régulièrement des formations à destination de professionnels dans le domaine
          des contrats publics et du contentieux administratif.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Diplôme Universitaire Médiateur – IFOMENE, ICP (2020)
          <br />
          <br />
          Associée du cabinet Pergame Avocats (depuis 2017)
          <br />
          <br />
          Associée du cabinet Earth avocats (2011-2017)
          <br />
          <br />
          Collaboratrice des cabinets Yves-René Guillou Avocats (2007-2011), De Pardieu, Brocas,
          Maffei (2005-2006) et Vatier & Associés (2002-2004)
          <br />
          <br />
          Institut de Droit Public des Affaires – EFB et Université de Paris X (2002)
          <br />
          <br />
          Master 2 (DESS) Contentieux national, international et européen- Université de Paris 5
          (2001)
          <br />
          <br />
          Master 2 (DEA) Droit public comparé – Université de Paris I (1999)
        </div>
      );
    }
    if (lang === 'en') {
      column1 = (
        <div>
          Clémence Cordier’s practice focuses on public law, with an emphasis on public contracts
          and regulatory matters. She was previously head of the litigation department of a law firm
          specialising in public economic law. She has strong litigation skills and a wealth of
          experience of administrative and civil courts.
          <br />
          <br />
          She also advises clients in the fast-growing area of alternative dispute resolution, and
          has received a training to that effect at the Paris Bar’s International School of
          Alternative Dispute Resolution (EIMA).
          <br />
          <br />
          Clémence teaches EU procurement law at Paris-East Marne La Vallée University, and
          regularly trains professionals in administrative and procurement litigation.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Pergame Avocats, Partner (since 2017)
          <br />
          <br />
          Earth Avocats, Paris, Partner (2011-2017)
          <br />
          <br />
          Yves-René Guillou Avocats, Associate (2007-2011)
          <br />
          <br />
          Symchowicz & Weissberg, Associate (2006-2007)
          <br />
          <br />
          De Pardieu, Brocas, Maffei, Associate (2005-2006)
          <br />
          <br />
          Vatier & Associés, Associate (2003-2004)
          <br />
          <br />
          Master’s degree in domestic, EU and international litigation, Paris-5 University (2001)
        </div>
      );
    }
  }

  if (id === 'Gagey') {
    src = (
      <span>
        <img src={gagey} alt={alt} className="portrait" />
        <br />
      </span>
    );
    title = 'Céline Gagey';
    subtitle = lang === 'fr' || !lang ? 'Associée' : 'Partner';
    alt = 'Gagey';
    href = 'mailto:gagey@pergame-avocats.com';
    mail = 'gagey@pergame-avocats.com';
    hrefVCF = 'https://www.pergame-avocats.com/Gagey.vcf';
    titleVCF = 'Céline Gagey.vcf';
    if (lang === 'fr' || !lang) {
      column1 = (
        <div>
          Céline Gagey est avocat publiciste et intervient principalement sur des questions liées à
          la passation et à l’exécution des contrats publics, au droit de l’environnement
          (installations classées pour la protection de l’environnement, IOTA) et au droit de la
          régulation.
          <br />
          <br />
          Elle conseille et assiste des opérateurs publics et privés principalement en
          précontentieux et contentieux.
          <br />
          <br />
          Céline dispose d’une connaissance approfondie des secteurs des infrastructures, des
          travaux publics et de l’énergie.
          <br />
          <br />
          Elle dispense des formations professionnelles en contentieux des marchés publics.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Associée du cabinet Pergame Avocats (depuis 2020)
          <br />
          <br />
          Associée du cabinet Alètheia (2019)
          <br />
          <br />
          Collaboratrice du cabinet Earth Avocats puis Counsel en charge du département contentieux
          du Cabinet Earth Avocats (2014-2018)
          <br />
          <br />
          Collaboratrice du cabinet Linklaters (2010-2014)
          <br />
          <br />
          Master II Droit Public des Affaires, Université Paris I Panthéon – Sorbonne
          <br />
          <br />
          Sciences Po Strasbourg – Section administration publique
        </div>
      );
    }
    if (lang === 'en') {
      column1 = (
        <div>
          Céline Gagey’s practice focuses on public law, with an emphasis on public contracts
          (tendering and performance), environmental law and regulatory matters.
          <br />
          <br />
          She represents public and private entities in court and during pre-litigation.
          <br />
          <br />
          Céline has excellent knowledge of the utilities, public works and energy sectors.
          <br />
          <br />
          She trains professionals in public contracts litigation.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Pergame Avocats, Partner (since 2020)
          <br />
          <br />
          Alètheia, Partner (2019)
          <br />
          <br />
          Earth Avocats, Counsel in charge of the litigation department and previously Associate
          (2014-2018)
          <br />
          <br />
          Linklaters, Associate (2010-2014)
          <br />
          <br />
          Master‘s Degree in Public Business Law, Paris-I University Panthéon – Sorbonne
          <br />
          <br />
          Sciences Po Strasbourg – Public administration section
        </div>
      );
    }
  }

  if (id === 'Oukid') {
    src = (
      <span>
        <img src={oukid} alt={alt} className="portrait" />
        <br />
      </span>
    );
    title = 'Ahmed Oukid';
    subtitle = lang === 'fr' || !lang ? 'Collaborateur' : 'Associate';
    alt = 'Oukid';
    href = 'mailto:oukid@pergame-avocats.com';
    mail = 'oukid@pergame-avocats.com';
    hrefVCF = 'https://www.pergame-avocats.com/Oukid.vcf';
    titleVCF = 'Ahmed Oukid.vcf';
    if (lang === 'fr' || !lang) {
      column1 = (
        <div>
          Ahmed Oukid intervient principalement dans des dossiers liés à la passation et à
          l’exécution de contrats de la commande publique et en droit public général. Il accompagne
          les collectivités publiques et les opérateurs économiques tant en conseil qu’en
          contentieux.
          <br />
          <br />
          Avant de rejoindre le cabinet Pergame Avocats, Ahmed Oukid a exercé pendant quatre ans en
          tant qu’assistant de justice au Conseil d’Etat et a été chargé de travaux dirigés à
          l’université Paris Cité.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Collaborateur du cabinet Pergame Avocats (depuis 2023)
          <br />
          <br />
          Assistant de justice au Conseil d’Etat (2019-2022)
          <br />
          <br />
          Master 2 de droit public économique – Sciences Po Paris (2018)
        </div>
      );
    }
    if (lang === 'en') {
      column1 = (
        <div>
          Ahmed Oukid's practice focuses on matters relating to the award and performance of public
          contracts and general public law. He advises and litigates on behalf of public authorities
          and economic operators.
          <br />
          <br />
          Before joining Pergame Avocats, Ahmed Oukid clerked at the Conseil d'Etat for four years
          and was a lecturer at the Université Paris Cité.
          <br />
          <br />
        </div>
      );
      column2 = (
        <div>
          Associate at Pergame Avocats (since 2023)
          <br />
          <br />
          Law clerk at the Conseil d’Etat (2019-2022)
          <br />
          <br />
          Master 2 in public economic law – Sciences Po Paris (2018)
        </div>
      );
    }
  }

  return (
    <>
      <Banner lang={lang} />

      <div
        id=""
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 90px 0',
        }}
      >
        <div className="container">
          <div
            onClick={() => history.goBack()}
            style={{
              cursor: 'pointer',
              padding: '10px',
              position: 'absolute',
              left: '30px',
              top: '-9px',
            }}
          >
            <img src={back} alt="back" className="pictosBack" />
          </div>
          <div className="sectionTitle">{title}</div>
          <div className="lineTitle" />

          <div
            className="columns custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '0',
              fontSize: '12px',
              lineHeight: '21px',
            }}
          >
            <div className="column col" style={{ paddingTop: '9px', paddingBottom: '80px' }}>
              <div className="fullPortrait">
                <div className="" style={{ marginBottom: '25px', paddingBottom: '3px' }}>
                  {src}
                  <div className="namePortrait">{title}</div>
                  <div className="subtitlePortrait">{subtitle}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                  <img src={letter} alt="letter" className="pictosPortrait" />
                  <span className="emailPortrait">
                    <a href={href}>{mail}</a>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <img src={vccard} alt="id" className="pictosPortrait" />
                  <span className="cardPortrait">
                    <a rel="external" href={hrefVCF} download={titleVCF}>
                      {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="column col"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              {column1}
            </div>

            <div
              id="CV"
              className="column col"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                paddingBottom: '0px',
              }}
            >
              {column2}
            </div>
          </div>
        </div>
      </div>

      <ContactSection lang={lang} />
    </>
  );
};

export default Lawyers;
