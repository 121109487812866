import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useDocuments } from '../Admin/firebase';
import _ from 'lodash';

import './News.css';
import back from '../../static/back.svg';

import bleu from '../../static/bleu3_optim_bis.jpg';
import ContactSection from '../../containers/ContactSection';
import Banner from '../../layouts/Banner';
// import { Item } from './Carousel'

const blue = '122342';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

const News = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { error, loading, documents } = useDocuments('news');

  const [newsToDisplay, setNewsToDisplay] = useState(6);
  const [polyhedreMarginTop, setPolyhedreMarginTop] = useState(0);

  let history = useHistory();
  let lang = useQuery().get('lang');

  let title = lang === 'en' ? 'News' : 'Actualités';

  if (loading) return null;

  return (
    <div>
      <Banner lang={lang} />

      <div
        id=""
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 90px 0',
        }}
      >
        <div className="container">
          <div
            onClick={() => history.goBack()}
            style={{
              cursor: 'pointer',
              padding: '10px',
              position: 'absolute',
              left: '30px',
              top: '-9px',
            }}
          >
            <img src={back} alt="back" className="pictosBack" />
          </div>
          <div className="sectionTitle">{title}</div>
          <div className="lineTitle" />

          <div
            className="columns custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '0',
              fontSize: '12px',
              lineHeight: '21px',
            }}
          >
            <div
              className="column"
              style={{
                paddingTop: polyhedreMarginTop,
                paddingLeft: '40px',
                paddingRight: '40px',
                paddingBottom: '0px',
              }}
            >
              <img
                id="polyhedreNews"
                src={bleu}
                style={{ marginTop: '20px' }}
              />
              {/* <div className="" style={{ width: '100%', backgroundColor: "red", margin: "0", paddingTop: "0px", paddingBottom: "0px", backgroundImage: `url(${bleu})`, backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundSize: "contain" }}>
              </div> */}
            </div>

            <div
              className="column is-two-thirds"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                padding: '0 40px',
              }}
            >
              <div id="newsColumn" className="tile is-ancestor is-vertical">
                <TransitionGroup>
                  {_.chunk(
                    documents
                      .filter((d) => d.status === 'published')
                      .slice(0, newsToDisplay),
                    2
                  ).map((d, i) => {
                    return (
                      <CSSTransition timeout={500} key={i} classNames="news">
                        <div className="tile" key={i}>
                          {d[0] && (
                            <Item
                              className="item"
                              document={d[0]}
                              lang={lang}
                            ></Item>
                          )}
                          {d[1] && (
                            <Item
                              className="item"
                              document={d[1]}
                              lang={lang}
                            ></Item>
                          )}
                        </div>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </div>
              {newsToDisplay < documents.length && (
                <div
                  onClick={(e) => {
                    setNewsToDisplay(newsToDisplay + 6);
                    console.log(
                      document.getElementById('newsColumn').offsetHeight
                    );
                    setPolyhedreMarginTop(
                      document.getElementById('newsColumn').offsetHeight
                    );
                  }}
                  style={{
                    paddingLeft: '0px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>{lang === 'en' ? 'More news' : 'Plus d’actualités'}</div>
                  <img
                    src={back}
                    alt="back"
                    className="pictosBack"
                    style={{ transform: 'rotate(180deg)', marginLeft: '15px ' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ContactSection lang={lang} />
    </div>
  );
};

export default News;

const Item = ({ lang, document }) => {
  let history = useHistory();

  return (
    <div
      onClick={() => {
        if (document['link type'] === 'none') return;
        if (document['link type'] === 'external')
          return openInNewTab(document['external link url']);
        history.push(
          lang ? `/News/${document.id}?lang=${lang}` : `/News/${document.id}`
        );
      }}
      className={
        document['link type'] === 'none'
          ? 'tile is-parent is-6'
          : 'article tile is-parent is-6'
      }
      style={{
        fontSize: '13px',
        lineHeight: '21px',
        fontFamily: 'Montserrat',
        textAlign: 'left',
        fontWeight: '500',
        letterSpacing: '0.5px',
      }}
    >
      <div
        className="tile is-child custom_justify"
        style={{ backgroundColor: '#F0F0F0', borderRadius: 2, padding: 30 }}
      >
        <div className="articleTitle" style={{ marginBottom: '25px' }}>
          {document.category}
        </div>
        <div
          style={{
            fontWeight: '300',
            fontSize: '12.5px',
            lineHeight: '21px',
            marginBottom: '15px',
          }}
        >
          {document.title}
        </div>
        <div
          style={{
            fontWeight: '300',
            fontSize: '12.5px',
            lineHeight: '21px',
            fontStyle: 'italic',
          }}
        >
          {document.reference}
        </div>
      </div>
    </div>
  );
};
