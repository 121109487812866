import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Banner from '../../layouts/Banner';

const blue = '122342';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let lang = useQuery().get('lang');

  return (
    <div>
      <Banner lang={lang} />

      <div
        id=""
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 90px 0',
        }}
      >
        <div className="container">
          <div className="sectionTitle">
            {lang === 'en' ? 'Legal notice' : 'Mentions légales'}
          </div>
          <div className="lineTitle" />

          <div
            className="columns custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '0',
              fontSize: '12.5px',
              lineHeight: '21px',
            }}
          >
            <div
              className="column"
              style={{ backgroundColor: '', padding: '10px 40px 10px 40px' }}
            >
              Conformément aux dispositions des articles 6-III-1° et 19 de la
              loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie
              numérique entrée en vigueur le 23 juin 2004, les coordonnées de
              l’éditeur et de l’hébergeur du site sont indiquées ci-dessous.
              <br />
              <br />
              <br />
              <span style={{ fontWeight: '500' }}>
                1. COORDONNÉES DE L’ÉDITEUR
                <br />
                <br />
              </span>
              Le présent site est édité par Pergame Avocats (Association
              d’Avocats à Responsabilité Professionnelle Individuelle).
              <br />
              Siège : 49, rue Saint-Roch, 75001 - PARIS <br /> Directeurs de la
              publication : Stanislas de Margerie, Pierre Reine, Clémence
              Cordier, Céline Gagey
              <br />
              {/*Téléphone : +33 (0)x xx xx xx xx<br/>*/}
              {/*Fax : +33 (0)x xx xx xx xx<br/>*/}
              {/*Email : contact@pergame-avocats.com<br/>*/}
              <br />
              <br />
              <span style={{ fontWeight: '500' }}>
                2. COORDONNÉES DE L’HÉBERGEUR
                <br />
                <br />
              </span>
              Le site est hébergé par la société GANDI (SAS immatriculée au RCS
              de PARIS sous le n° 42309345900042).
              <br />
              Siège : 63-65 Boulevard Masséna, 75013 Paris
              <br />
              <br />
            </div>
            <div
              className="column"
              style={{
                backgroundColor: '',
                margin: '0',
                padding: '10px 40px 10px 40px',
              }}
            >
              <span style={{ fontWeight: '500' }}>
                3. PROPRIÉTÉ INTELLECTUELLE
                <br />
                <br />
              </span>
              L'ensemble du site relève des législations française et
              internationale sur le droit d'auteur et la propriété
              intellectuelle. Tous les droits de reproduction sont réservés, y
              compris pour les documents iconographiques et photographiques.
              <br />
              <br />
              La reproduction de tout ou partie de ce site sur un support
              électronique quel qu&rsquo;il soit est formellement interdite sauf
              autorisation expresse du directeur de la publication.
              <br />
              <br />
              Toutefois, la reproduction des textes de ce site sur support
              papier est autorisée, notamment à des fins pédagogiques, sous
              réserve du respect des trois conditions suivantes :<br />
              <br />
              • gratuité de la diffusion,
              <br />
              <br />
              • respect de l&rsquo;intégralité des documents reproduits sans
              modification ni altération d&rsquo;aucune sorte,
              <br />
              <br />
              • citation claire et lisible de la source (par exemple : « ce
              document provient du site www.pergame-avocats.com »).
              <br />
              <br />
            </div>
            <div
              className="column"
              style={{
                backgroundColor: '',
                margin: '0',
                padding: '10px 40px 10px 40px',
              }}
            >
              <span style={{ fontWeight: '500' }}>
                4. CRÉDITS
                <br />
                <br />
              </span>
              Photographies : Raphaël Denis, Eléments pour un ensemble (2013),
              plâtre pigmenté 67 x 74 x 42 cm, courtesy de l&rsquo;artiste.
              <br />
              <br />
              Création du site : Elisabeth Frouart
              <br />
              <br />
              Développement du site : Hadrien Lepage
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;
