import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Home.css';

import image from '../../static/bleu2_optim.jpg';
import margerie from '../../static/margerie4.jpg';
import reine from '../../static/Reine3.jpg';
import cordier from '../../static/Cordier3.jpg';
import gagey from '../../static/Gagey3.jpg';
import oukid from '../../static/Oukid.jpg';
import letter from '../../static/letter.svg';
import legal500 from '../../static/500.png';
import id from '../../static/id.svg';
import ContactSection from '../../containers/ContactSection';
import Carousel from '../News/Carousel';
import Banner from '../../layouts/Banner';

const blue = '122342';
const gray = 'rgb(240,240,240)';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  let lang = useQuery().get('lang');

  // initMap() {
  //   var mrc = {lat: 48.1248, lng: 2.320};
  //   var map = new google.maps.Map(document.getElementById('map'), {
  //     zoom: 15,
  //     center: mrc
  //   });
  //   var marker = new google.maps.Marker({
  //     position: mrc,
  //     map: map
  //   });
  // }

  return (
    <div>
      <div
        id="image"
        title="Raphaël Denis, Eléments pour un ensemble (2013)"
        className="image"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundColor: '#f0f0f0',
        }}
      />

      <Banner lang={lang} />

      <div
        id="about"
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 0px 0',
        }}
      >
        {lang === 'en' ? (
          <div className="container">
            <div className="sectionTitle">About us</div>
            <div className="lineTitle" />

            <div
              className="columns custom_justify"
              style={{
                margin: '60px 0 0 0',
                padding: '0',
                fontSize: '12.5px',
                lineHeight: '21px',
              }}
            >
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                Pergame Avocats is a law firm specialising in administrative and regulatory law.{' '}
                <br />
                <br />
                The firm was founded by lawyers who decided to bring together their expertise to
                offer their clients a specialist service in the areas of administrative and
                regulatory law.
                <br />
                <br />
                Pergame Avocats’s clients are primarily companies and public entities. The firm
                represents its clients in litigation, provides them with legal advice, and assists
                them in defining their legal strategies.
                <br />
                <br />
                All partners are well-versed in litigation before the French administrative courts
                and regulatory authorities.
              </div>
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  margin: '0',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                The firm extolls the highest standards of professional conduct, in particular
                avoiding conflicts of interest. When cases require legal expertise beyond the scope
                of administrative and regulatory law, the partners can recommend appropriate law
                firms.
                <br />
                <br />
                The Legal 500 rankings for 2024 recognised Pergame Avocats as one of the best firms
                for Administrative and Public Law in France.
                <img src={legal500} className="h-32 mt-10" />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="sectionTitle">Cabinet</div>
            <div className="lineTitle" />

            <div
              className="columns custom_justify"
              style={{
                margin: '60px 0 0 0',
                padding: '0',
                fontSize: '12.5px',
                lineHeight: '21px',
              }}
            >
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                Pergame Avocats est un cabinet d’avocats dont l’activité est dédiée au droit public
                des affaires.
                <br />
                <br />
                Le cabinet réunit des avocats publicistes expérimentés, travaillant ensemble depuis
                plusieurs années, qui ont souhaité mettre au profit de leurs clients un service de
                haute qualité dans leur domaine commun de compétence. Le cabinet est à dimension
                humaine, avec une implication complète des associés du début à la fin de chaque
                dossier.
                <br />
                <br />
                Le cabinet intervient en conseil et en contentieux, auprès d’une clientèle
                constituée principalement d’entreprises privées et de personnes publiques. Les
                associés sont en mesure d’éclairer leurs clients sur les enjeux et risques de leurs
                projets.
                <br />
                <br />
                Ils les conseillent sur les stratégies les mieux adaptées à leurs objectifs. Dans un
                contexte marqué par l’accroissement du contentieux, les associés disposent tous
                d’une excellente connaissance des juridictions administratives.
              </div>
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  margin: '0',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                Le cabinet s’astreint au respect strict des règles déontologiques qui régissent la
                profession d’avocat, en particulier la prévention des risques de conflits
                d’intérêts. Lorsque certains dossiers font appel à des compétences sortant du champ
                du droit public, les associés sont habitués à travailler en équipe avec d’autres
                avocats et disposent d’un réseau de cabinets de confrères qu’ils peuvent recommander
                aux clients qui le souhaitent.
                <br />
                <br />
                Le cabinet a été distingué dans le classement Legal 500 des meilleurs cabinets
                d’avocats en droit public en France (Legal 500, édition 2024).
                <img src={legal500} className="h-32 mt-10" />{' '}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        id="news"
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0px 90px 0px',
        }}
      >
        <div className="container" style={{ padding: '0 40px' }}>
          <Link to={lang ? `/News?lang=${lang}` : '/News'}>
            <div
              className="newsTitle"
              style={{
                fontWeight: 500,
                fontSize: '16px',
                letterSpacing: '0.8px',
              }}
            >
              {lang === 'en' ? 'News' : 'Actualités'}
            </div>
          </Link>
          {/* <div className="lineTitle" /> */}
          <Carousel />
        </div>
      </div>

      <div
        id="expertises"
        className="section"
        style={{
          backgroundColor: `${gray}`,
          color: 'black',
          padding: '40px 0 100px 0',
        }}
      >
        <div className="container">
          <div className="sectionTitle">Expertise</div>
          <div className="lineTitle" />

          {lang === 'en' ? (
            <div
              className="columns custom_justify"
              style={{
                margin: '20px 0 0 0',
                padding: '0',
                fontSize: '12.5px',
                lineHeight: '21px',
              }}
            >
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
              >
                <div className="paragraph">
                  <div className="paragraphTitle">Public procurement and public contracts</div>
                  The partners assist their clients with all types of public contracts, including:
                  public procurement; public service delegation contracts; works concessions; and
                  agreements granting the right to occupy public land. Their expertise ranges from
                  the tendering stage to contract performance.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">Urban planning and construction law</div>
                  The firm’s practice covers urban planning and construction law. Its client base
                  includes companies (builders, developers, architects, individuals) and public
                  entities (local authorities, the State, and other public bodies). The firm
                  represents its clients before administrative and civil courts.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">Environmental law</div>
                  The firm’s practice covers environmental law (legal advice and litigation). It
                  notably advises its clients on matters of environmental authorisations and
                  renewable energies.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">Criminal procurement law</div>
                  The firm’s practice covers criminal law, in particular the criminal law aspects of
                  procurement. It assists public contracting authorities in identifying and reducing
                  criminal risk and defends them in criminal proceedings.
                </div>
              </div>
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
              >
                <div className="paragraph">
                  <div className="paragraphTitle">Regulatory</div>
                  The partners assist companies and public entities operating in highly regulated
                  sectors (e.g. health, transport, gambling) and advise on cross-sectoral regulation
                  such as the EU General Data Protection Regulation (GDPR). They represent their
                  clients in proceedings before regulatory authorities and the courts. The firm has
                  a special focus on the regulatory challenges faced by startups.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">Public health law</div>
                  The firm assists clients from the health sector in structuring their organisation
                  of care services and complying with public health regulations.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">Public competition law and State aid</div>
                  The partners advise their clients on all matters of subsidies and State aid, as
                  well as competition law applicable to the public sector.
                </div>

                <div className="paragraph">
                  <div className="paragraphTitle">General public law</div>
                  The partners are well-versed in other key areas of public law, such as: <br />
                  • Constitutional law
                  <br />
                  • Local government law
                  <br />
                  • Administrative liability
                  <br />
                  • Civil service law
                  <br />
                  • Energy law
                  <br />• Expropriation law
                </div>
              </div>
            </div>
          ) : (
            <div
              className="columns custom_justify"
              style={{
                margin: '20px 0 0 0',
                padding: '0',
                fontSize: '12.5px',
                lineHeight: '21px',
              }}
            >
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
              >
                <Link
                  to={lang ? `/Expertises/Administratif?lang=${lang}` : '/Expertises/Administratif'}
                >
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">
                      Droit de la commande publique et des contrats administratifs
                    </div>
                    Les avocats du cabinet interviennent sur les problématiques de commande publique
                    et de contrats publics (marchés publics, délégations de service public,
                    concessions de travaux, contrats d’occupation du domaine public, etc.).
                    <br />
                    <br />
                    Le cabinet dispose d’une expertise pointue en matière de contentieux de la
                    passation (référés précontractuels et recours en contestation de la validité du
                    contrat) et de l’exécution des contrats (notamment pour les contestations de DGD
                    et les litiges liés à des désordres et malfaçons).
                  </div>
                </Link>

                <Link to={lang ? `/Expertises/Urbanisme?lang=${lang}` : '/Expertises/Urbanisme'}>
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">
                      Droit de l’urbanisme et de la construction
                    </div>
                    Le cabinet intervient en droit de l’urbanisme et de la construction,
                    essentiellement en contentieux, aussi bien pour des clients privés
                    (constructeurs, promoteurs, particuliers) que publics ou parapublics
                    (collectivités territoriales, établissements publics, sociétés d’économie
                    mixte). Le cabinet défend ses clients devant les juridictions administratives ou
                    judiciaires, en demande ou en défense, pour les contentieux dirigés contre les
                    règlements d’urbanisme et les autorisations d’urbanisme.
                  </div>
                </Link>

                <Link
                  to={lang ? `/Expertises/Environnement?lang=${lang}` : '/Expertises/Environnement'}
                >
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">Droit de l’environnement</div>
                    Le cabinet intervient en droit de l’environnement, tant en conseil qu’en
                    contentieux. Il conseille ses clients sur des problématiques liées à des
                    autorisations environnementales (ICPE, IOTA). Les avocats du cabinet disposent
                    également d’une solide expérience en droit des énergies renouvelables.
                  </div>
                </Link>

                <Link to={lang ? `/Expertises/Penal?lang=${lang}` : '/Expertises/Penal'}>
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">Droit pénal de la commande publique</div>
                    Le cabinet intervient en droit pénal, spécifiquement pour les infractions en
                    lien avec la commande publique (délit de favoritisme et prise illégale
                    d’intérêt). Il conseille en amont les acheteurs publics afin d’identifier les
                    risques et les prévenir, et assiste les personnes poursuivies pénalement pour
                    ces délits.
                  </div>
                </Link>
              </div>
              <div
                className="column col"
                style={{
                  backgroundColor: '',
                  paddingTop: '10px',
                  paddingBottom: '0px',
                }}
              >
                <Link
                  to={lang ? `/Expertises/Sectorielle?lang=${lang}` : `/Expertises/Sectorielle`}
                >
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">Règlementaire</div>
                    Les avocats du cabinet interviennent pour le compte d’opérateurs publics et
                    privés actifs dans des secteurs fortement régulés par la puissance publique
                    (santé, transports, énergie, débits de boisson et établissements de nuit, jeux…)
                    ou soumis à des réglementations transversales telle que le règlement général sur
                    la protection des données (RGPD). Ils disposent de compétences en matière de
                    police économique, notamment pour la contestation des décisions prises par
                    l’Etat dans le cadre de ses missions de régulation et de réglementation.
                  </div>
                </Link>

                <Link to={lang ? `/Expertises/Sante?lang=${lang}` : '/Expertises/Sante'}>
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">Droit de la santé publique</div>
                    Le cabinet conseille les acteurs du secteur de la santé sur les questions
                    d’organisation et de structuration de l’offre de soins et sur les diverses
                    règlementations en droit de la santé publique.
                  </div>
                </Link>

                <Link
                  to={lang ? `/Expertises/Concurrence?lang=${lang}` : '/Expertises/Concurrence'}
                >
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">
                      Droit public de la concurrence et aides d’Etat
                    </div>
                    Les avocats interviennent en matière de subventions publiques, d’aides d’état et
                    de droit de la concurrence appliqué au secteur public.
                  </div>
                </Link>

                <Link to={lang ? `/Expertises/Public?lang=${lang}` : '/Expertises/Public'}>
                  <div className="paragraphFr">
                    <div className="paragraphTitleFr">Droit public général</div>
                    Les avocats disposent de compétences transversales en droit public et
                    accompagnent régulièrement leurs clients sur leurs dossiers de :<br />
                    • Droit constitutionnel ;<br />
                    • Droit de la fonction publique et des agents non titulaires ;<br />
                    • Droit disciplinaire ;<br />
                    • Droit des collectivités territoriales et des établissements publics ;
                    <br />
                    • Droit de l’énergie ;<br />• Droit de l’expropriation.
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        id="team"
        className="section"
        style={{
          backgroundColor: '',
          color: 'black',
          padding: '40px 0 20px 0',
        }}
      >
        <div className="container">
          <div className="sectionTitle">{lang === 'en' ? 'Team' : <span>&#201;quipe</span>}</div>
          <div className="lineTitle" />
          <div
            className="team_home custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '10px 20px 80px 20px',
              fontSize: '12px',
              lineHeight: '21px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className="centerPortrait1">
              <Link to={lang ? `/Lawyers/Margerie?lang=${lang}` : '/Lawyers/Margerie'}>
                <div className="clickPortrait">
                  <img src={margerie} alt="Margerie" className="portraitMain" />
                  <br />
                  <div className="namePortrait">Stanislas de Margerie</div>
                  <div className="subtitlePortrait">{lang === 'en' ? 'Partner' : 'Associé'}</div>
                </div>
              </Link>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: 25 }}>
                <img src={letter} alt="letter" className="pictosPortrait" />
                <span className="emailPortrait">
                  <a href="mailto:margerie@pergame-avocats.com">margerie@pergame-avocats.com</a>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <img src={id} alt="id" className="pictosPortrait" />
                <span className="cardPortrait">
                  <a
                    rel="external"
                    href="https://www.pergame-avocats.com/Margerie.vcf"
                    download="Stanislas de Margerie.vcf"
                  >
                    {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                  </a>
                </span>
              </div>
            </div>

            <div className="centerPortrait2">
              <Link to={lang ? `/Lawyers/Cordier?lang=${lang}` : '/Lawyers/Cordier'}>
                <div className="clickPortrait">
                  <img src={cordier} alt="Cordier" className="portraitMain" />
                  <br />
                  <div className="namePortrait">Clémence Cordier</div>
                  <div className="subtitlePortrait">{lang === 'en' ? 'Partner' : 'Associée'}</div>
                </div>
              </Link>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: 25 }}>
                <img src={letter} alt="letter" className="pictosPortrait" />
                <span className="emailPortrait">
                  <a href="mailto:cordier@pergame-avocats.com">cordier@pergame-avocats.com</a>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <img src={id} alt="id" className="pictosPortrait" />
                <span className="cardPortrait">
                  <a
                    rel="external"
                    href="https://www.pergame-avocats.com/Cordier.vcf"
                    download="Clémence Cordier.vcf"
                  >
                    {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                  </a>
                </span>
              </div>
            </div>

            <div className="centerPortrait2">
              <Link to={lang ? `/Lawyers/Reine?lang=${lang}` : '/Lawyers/Reine'}>
                <div className="clickPortrait">
                  <img src={reine} alt="Reine" className="portraitMain" />
                  <br />
                  <div className="namePortrait">Pierre Reine</div>
                  <div className="subtitlePortrait">{lang === 'en' ? 'Partner' : 'Associé'}</div>
                </div>
              </Link>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: 25 }}>
                <img src={letter} alt="letter" className="pictosPortrait" />
                <span className="emailPortrait">
                  <a href="mailto:reine@pergame-avocats.com">reine@pergame-avocats.com</a>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <img src={id} alt="id" className="pictosPortrait" />
                <span className="cardPortrait">
                  <a
                    rel="external"
                    href="https://www.pergame-avocats.com/Reine.vcf"
                    download="Pierre Reine.vcf"
                  >
                    {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                  </a>
                </span>
              </div>
            </div>

            <div className="centerPortrait2">
              <Link to={lang ? `/Lawyers/Gagey?lang=${lang}` : '/Lawyers/Gagey'}>
                <div className="clickPortrait">
                  <img src={gagey} alt="Gagey" className="portraitMain" />
                  <br />
                  <div className="namePortrait">Céline Gagey</div>
                  <div className="subtitlePortrait">{lang === 'en' ? 'Partner' : 'Associée'}</div>
                </div>
              </Link>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: 25 }}>
                <img src={letter} alt="letter" className="pictosPortrait" />
                <span className="emailPortrait">
                  <a href="mailto:gagey@pergame-avocats.com">gagey@pergame-avocats.com</a>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <img src={id} alt="id" className="pictosPortrait" />
                <span className="cardPortrait">
                  <a
                    rel="external"
                    href="https://www.pergame-avocats.com/Gagey.vcf"
                    download="Céline Gagey.vcf"
                  >
                    {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                  </a>
                </span>
              </div>
            </div>

            <div className="centerPortrait3">
              <Link to={lang ? `/Lawyers/Oukid?lang=${lang}` : '/Lawyers/Oukid'}>
                <div className="clickPortrait">
                  <img src={oukid} alt="Oukid" className="portraitMain" />
                  <br />
                  <div className="namePortrait">Ahmed Oukid</div>
                  <div className="subtitlePortrait">
                    {lang === 'en' ? 'Associate' : 'Collaborateur'}
                  </div>
                </div>
              </Link>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: 25 }}>
                <img src={letter} alt="letter" className="pictosPortrait" />
                <span className="emailPortrait">
                  <a href="mailto:oukid@pergame-avocats.com">oukid@pergame-avocats.com</a>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <img src={id} alt="id" className="pictosPortrait" />
                <span className="cardPortrait">
                  <a
                    rel="external"
                    href="https://www.pergame-avocats.com/Oukid.vcf"
                    download="Ahmed Oukid.vcf"
                  >
                    {lang === 'en' ? 'Download vCard' : 'Télécharger la carte de visite'}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSection lang={lang} />

      {/* <div id="map" className="map" style={{height: "300px", width: "100%"}}></div> */}
    </div>
  );
};

export default Home;

// const lawyers = [
//   {
//     name:
//     fullName:

//   }
// ]
