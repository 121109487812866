import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import './Expertises.css';

import back from '../../static/back.svg';

import bleu from '../../static/bleu3_optim.jpg';
import ContactSection from '../../containers/ContactSection';
import Banner from '../../layouts/Banner';

const blue = '122342';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Expertises = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const { id } = useParams();
  let lang = useQuery().get('lang');

  let title = null;
  let column1 = null;
  let column2 = null;

  if (id === 'Administratif') {
    title = 'Droit de la commande publique et des contrats administratifs';
    column1 = (
      <div>
        Les avocats du cabinet interviennent sur les problématiques de commande
        publique et de contrats publics (marchés publics, délégations de service
        public, concessions de travaux, contrats d’occupation du domaine public,
        etc.).
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>En conseil</span>, le cabinet est
        compétent notamment pour :
        <br />
        <br />• L’assistance à maîtrise d’ouvrage des personnes publiques :
        assistance au choix et à la structuration de montages contractuels,
        assistance dans les procédures de publicité et de mise en concurrence,
        négociation et rédaction de contrats ;
        <br />
        <br />• L’assistance des entreprises : préparation des dossiers de
        candidatures et dossiers d’offres, négociation et rédaction des contrats
        ;
        <br />
        <br />• L’audit contractuel et l’analyse des risques contractuels ;
        <br />
        <br />• Le suivi d’exécution des contrats (conformité aux délais
        contractuels, performances, calcul des pénalités, etc.) et l’assistance
        à la négociation et la rédaction d’avenants.
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>En contentieux</span>, le cabinet
        intervient notamment pour :
        <br />
        <br />• L’assistance lors d’une éviction litigieuse d’une procédure de
        mise en concurrence (analyse des risques / chances d’annulation de la
        procédure de passation en référé précontractuel) ;
        <br />
        <br />• L’assistance lors des difficultés d’exécution des contrats
        publics et lors de l’établissement du décompte (assistance aux
        réclamations ou aux réponses à ces dernières) ;
        <br />
        <br />• La représentation dans les expertises judiciaires, notamment
        dans le contexte des marchés de travaux ;
        <br />
        <br />• La négociation et la rédaction de transactions ;
        <br />
        <br />• Le contentieux des contrats publics (passation et exécution, en
        référé ou devant le juge du fond).
      </div>
    );
    column2 = (
      <div>
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus
        </span>{' '}
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Etablissement public de l’Armée
        </span>{' '}
        : assistance et représentation en justice pour le traitement de
        l’ensemble des contentieux contractuels (passation et exécution) ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Entreprise de fourniture pour les grands réseaux de transport
        </span>{' '}
        : assistance dans le cadre de négociations contractuelles, assistance et
        représentation en justice pour plusieurs procédures de passation de
        contrats publics ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupement de coopération sanitaire regroupant 120 adhérents
        </span>
         : assistance à la commande publique et représentation en justice à
        l’occasion de référés précontractuels ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupe leader français en matière de transport urbain de voyageurs
        </span>
         : assistance et représentation en justice à l’occasion d’une
        contestation de la régularité d’une procédure d’attribution de
        délégation de service public ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Ville de 45 000 habitants</span> :
        assistance et représentation en justice pour la gestion de désordres
        affectant le Parc des expositions financé, construit et maintenu dans le
        cadre d’un marché de partenariat (expertise judiciaire – médiation
        conventionnelle – protocoles transactionnels et avenant) ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupe européen de matériaux de construction
        </span>{' '}
        : assistance et représentation en justice d’une entreprise étrangère
        leader dans son secteur dans le cadre de l’exécution de plusieurs
        marchés publics de travaux en France (expertises amiables et judicaires,
        action en responsabilité décennale) ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Etablissement public pour l’aménagement d’une grande agglomération
        </span>
         : conseil pour la réception et l’établissement du décompte d’un marché
        de travaux dont la réalisation a été marquée par de graves défaillances
        de sécurité, préjudiciables au maître d’ouvrage ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Commune</span> : assistance
        juridique pour le montage d’une société d'économie mixte à opération
        unique (SEMOP) pour la modernisation et le développement de son réseau
        de chauffage urbain ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Major du BTP</span> :
        accompagnement dans le cadre d’une expertise sur des problématiques
        d’exécution d’un important marché de travaux ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Etablissement public de l’Etat dans le domaine de la culture
        </span>
         : assistance du maître d’ouvrage délégué contre le titulaire d’un
        marché de travaux et son sous-traitant dans le cadre de la contestation
        du décompte et dans l’opposition à un titre exécutoire ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Communauté d’agglomération</span> :
        mission d’assistance à maîtrise d’ouvrage pour la passation d’une
        convention de délégation de service public de transport urbain et
        péri-urbain ; audit contractuel de la précédente convention.
      </div>
    );
  }
  if (id === 'Urbanisme') {
    title = 'Droit de l’urbanisme et de la construction';
    column1 = (
      <div>
        Le cabinet intervient en droit de l’urbanisme et de la construction,
        essentiellement en contentieux, aussi bien pour des clients privés
        (constructeurs, promoteurs, particuliers) que publics ou parapublics
        (collectivités territoriales, établissements publics, sociétés
        d’économie mixte).
        <br />
        <br />
        <br />
        Le cabinet défend ses clients devant les juridictions administratives ou
        judiciaires, en demande ou en défense, pour les contentieux dirigés
        contre les règlements d’urbanisme et les autorisations d’urbanisme.
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus
        </span>{' '}
        :
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Copropriété</span> :
        introduction d’un recours en annulation contre les permis de construire
        relatifs à un projet immobilier majeur en banlieue parisienne ; conduite
        des négociations ayant permis de trouver une issue transactionnelle au
        litige ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Entreprise de logement social</span>
         : assistance et représentation en justice dans le cadre d’une action en
        démolition faisant suite à l’annulation d’un permis de construire ;
      </div>
    );
    column2 = (
      <div>
        • <span style={{ fontWeight: '500' }}>Bailleur social</span> :
        assistance et représentation en justice pour demander l’annulation d’un
        arrêté du maire opposant un sursis à statuer à sa demande de permis de
        construire ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Société civile immobilière</span> :
        assistance et représentation en justice pour demander l’annulation d’une
        délibération approuvant le PLU de la commune ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Entreprise de construction</span> :
        assistance et représentation en justice d’un pétitionnaire, pour
        défendre, en intervention volontaire, la légalité d’un permis de
        construire pour l’édification d’un parc des expositions.
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Etablissement public de l’Etat dans le domaine de la culture
        </span>
         : référés préventifs préalables à des travaux de construction ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Centre hospitalier</span> :
        assistance pour la mise en jeu de la garantie décennale portant sur des
        équipements indissociables du bâtiment.
      </div>
    );
  }
  if (id === 'Environnement') {
    title = 'Droit de l’environnement';
    column1 = (
      <div>
        Le cabinet intervient en droit de l’environnement, tant en conseil qu’en
        contentieux. Il conseille ses clients sur des problématiques liées à des
        autorisations environnementales (ICPE, IOTA). Les avocats du cabinet
        disposent également d’une solide expérience en droit des énergies
        renouvelables.
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus
        </span>
         :
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Conseil départemental</span>
         : assistance et représentation en justice pour défendre la légalité
        d’un arrêté préfectoral d’autorisation « loi sur l’eau » ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Association de défense de l’environnement
        </span>
         : introduction de divers recours relatifs à des projets éoliens en
        Bourgogne ;
      </div>
    );
    column2 = (
      <div>
        • <span style={{ fontWeight: '500' }}>Préfecture</span> : assistance et
        représentation en justice pour défendre la légalité d’un arrêté
        préfectoral d’autorisation « loi sur l’eau » ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Syndicat mixte d’adduction d’eau potable
        </span>
         : assistance et représentation en justice pour défendre la légalité
        d’un arrêté préfectoral déclarant d’utilité publique des travaux de
        prélèvement et de dérivation des eaux et la mise en place des périmètres
        de protection du captage ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Préfecture</span> :
        assistance et représentation en justice de l’Etat pour défendre la
        légalité d’un arrêté autorisant la destruction de spécimens d’espèces
        protégées.
      </div>
    );
  }
  if (id === 'Penal') {
    title = 'Droit pénal de la commande publique';
    column1 = (
      <div>
        Le cabinet intervient en droit pénal, spécifiquement pour les
        infractions en lien avec la commande publique (délit de favoritisme et
        prise illégale d’intérêt). Il conseille en amont les acheteurs publics
        afin d’identifier les risques et les prévenir, et assiste les personnes
        poursuivies pénalement pour ces délits.
        <br />
        <br />
        <br />
        Ainsi, les avocats sont compétents pour :
        <br />
        <br />• Assister et défendre des personnes mise en cause ;
        <br />
        <br />• Assister des personnes mises en cause dans le cadre de
        procédures de contrôle budgétaire, financier et comptable ;
        <br />
        <br />• Auditer les risques pénaux et formaliser des guides de bonnes
        pratiques ou des codes de déontologie, etc. ;
        <br />
        <br />• Assister leurs clients sur les problématiques de protection
        fonctionnelle.
      </div>
    );
    column2 = (
      <div>
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus
        </span>{' '}
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Régie municipale de distribution d’eau potable
        </span>
         : assistance pour le dépôt de plainte à l’encontre du titulaire d’un
        marché public portant sur les clôtures d’un périmètre sourcier, pour des
        délits d’enfouissement de déchets sans autorisation et de prise illégale
        d’intérêt ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Entreprise d’exportation d’équipements paramilitaires
        </span>
         : assistance d’un mis en examen dans le cadre d’une instruction portant
        sur des faits de corruption de fonctionnaire et de blanchiment ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Société informatique</span>
         : assistance du titulaire de marchés signés sans mise en concurrence,
        pour évaluer le risque pénal de recel de favoritisme et préconiser des
        solutions ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Salarié d’une entreprise publique
        </span>
         : assistance et représentation en justice d’un salarié cité à
        comparaître par une association partie civile pour des faits de
        favoritisme et de prise illégale d’intérêts.
      </div>
    );
  }
  if (id === 'Sectorielle') {
    title = 'Règlementaire';
    column1 = (
      <div>
        Les avocats du cabinet interviennent pour le compte d’opérateurs publics
        et privés actifs dans des secteurs fortement régulés par la puissance
        publique (santé, transports, énergie, débits de boisson et
        établissements de nuit, jeux…) ou soumis à des réglementations
        transversales telle que le règlement général sur la protection des
        données (RGPD). Ils disposent de compétences en matière de police
        économique, notamment pour la contestation des décisions prises par
        l’Etat dans le cadre de ses missions de régulation et de réglementation.
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus en matière règlementaire
        </span>{' '}
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Opérateur de vélos en libre-service sans station
        </span>{' '}
        : conseil pour le déploiement de l’offre en France et les relations avec
        les collectivités ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Laboratoire pharmaceutique</span> :
        conseil pour la défense devant les juridictions administratives
        d’autorisations de mise sur le marché de médicaments contestées par un
        concurrent ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Exploitant d’établissements de nuit
        </span>{' '}
        : assistance dans le cadre de mesures de fermetures administratives ;
      </div>
    );
    column2 = (
      <div>
        • <span style={{ fontWeight: '500' }}>Opérateur de jeux de hasard</span>
         : représentation en justice pour demander l’abrogation des dispositions
        règlementaires relatives au monopole de la Française des Jeux ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Groupement foncier agricole</span> :
        représentation en justice dans un litige relatif à l’aptitude de
        terrains à produire un vin répondant aux qualités exigées pour
        l’appellation d’une AOC.
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus en matière de données personnelles
        </span>{' '}
        :
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Fintech</span> : audit de
        conformité au RGPD et accompagnement pour la mise en conformité ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Start-up dans le secteur de l’immobilier
        </span>
         : assistance lors de la conception d’un projet de plateforme pour
        assurer sa conformité au RGPD ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Plateforme de participation citoyenne en ligne
        </span>
         : audit de conformité au RGPD et accompagnement pour la mise en
        conformité.
      </div>
    );
  }
  if (id === 'Sante') {
    title = 'Droit de la santé publique';
    column1 = (
      <div>
        Le cabinet accompagne les acteurs du secteur de la santé sur les
        questions d’organisation et de structuration de l’offre de soins :
        <br />
        <br />• Des GHT, GCS, centres hospitaliers et EHPAD pour la création de
        structures inter-établissements et de montages de mutualisation ;
        <br />
        <br />• Des professionnels de santé de premier recours et associations
        pour la création de structures de coordination de soins (SISA,
        association porteuse de CPTS, GIE, etc.).
        <br />
        <br />
        Le cabinet les conseille également sur les diverses règlementations en
        matière de santé publique (droit des patients, autorisations délivrées
        par l’ARS, déontologie médicale, etc.).
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus en matière règlementaire
        </span>{' '}
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupement hospitalier de territoire
        </span>
         : assistance en vue de la création d’un service de santé au travail
        inter-hospitalier (rédaction d’une convention inter-établissement, du
        règlement intérieur, des conventions de mise à disposition de
        personnels, etc.) ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupement de coopération sanitaire
        </span>
         : assistance en vue de refondre la convention constitutive et le
        règlement intérieur du GCS au regard des évolutions de la règlementation
        sur les GHT et des nouveaux besoins des membres.
      </div>
    );
    column2 = (
      <div>
        • <span style={{ fontWeight: '500' }}>Centre hospitalier</span> :
        assistance sur le montage contractuel à conclure avec une SCP souhaitant
        exercer l’activité de médecine nucléaire au sein de l’établissement ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Centre hospitalier</span> :
        assistance et représentation en justice pour le recouvrement de créances
        auprès de la CPAM (prestations de soins pharmaceutiques et de soins
        urgents) ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Centre hospitalier</span> :
        assistance à la contractualisation d’un adossement de structures avec
        une clinique privée pour mettre en commun un plateau technique ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Associations de professionnels de santé de premier recours
        </span>
         : assistance à la restructuration de réseaux de santé avec la création
        d’une association support d’une CPTS et la liquidation/fusion de
        plusieurs associations existantes ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Associations de professionnels de santé de premier recours
        </span>
         : assistance à la création de sociétés interprofessionnelles de soins
        ambulatoires ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Organisme leader de la formation professionnelle dans le secteur de
          l’hôtellerie-restauration
        </span>
         : délivrance de formations en droit de la santé publique sanctionnées
        par le permis d’exploiter.
      </div>
    );
  }
  if (id === 'Concurrence') {
    title = 'Droit public de la concurrence et aides d’Etat';
    column1 = (
      <div>
        Les avocats interviennent en matière de subventions publiques, d’aides
        d’état et de droit de la concurrence appliqué au secteur public.
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus
        </span>{' '}
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Groupement d’entreprises industrielles
        </span>
         : définition et mise en œuvre de stratégies de lobbying et
        contentieuses ayant pour objet d’obtenir la cessation d’une activité
        concurrentielle exercée par des opérateurs publics ;
      </div>
    );
    column2 = (
      <div>
        •{' '}
        <span style={{ fontWeight: '500' }}>Etablissement public national</span>
         : défense dans divers recours en contestation de validité de marchés
        publics attribués à l’établissement fondés sur les avantages
        concurrentiels et les aides d’Etat dont ce dernier aurait bénéficié ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Ordre national professionnel</span>
         : conseil et assistance d’un ordre professionnel pour répondre à une
        notification des griefs adressée par la Commission européenne (DG
        concurrence) sur le fondement d’une restriction à un marché
        concurrentiel.
      </div>
    );
  }
  if (id === 'Public') {
    title = 'Droit public général';
    column1 = (
      <div>
        Les avocats disposent de compétences transversales en droit public et
        accompagnent régulièrement leurs clients sur leurs dossiers de :
        <br />
        <br />• Droit constitutionnel ;
        <br />• Droit des collectivités territoriales et des établissements
        publics ;
        <br />• Droit de la fonction publique et des agents non titulaires ;
        <br />• Droit disciplinaire ;
        <br />• Droit de l'énergie ;
        <br /> • Droit de l’expropriation.
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          Références de dossiers pour lesquels les avocats du cabinet sont
          intervenus en droit constitutionnel{' '}
        </span>
        :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Association de défense de l’environnement
        </span>
         : rédaction et dépôt d’observations auprès du Conseil constitutionnel
        via une « porte étroite » (loi du 30 juillet 2018 relative à la
        protection du secret des affaires) ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Association de microcrédit pour la création d'entreprises
        </span>
         : représentation juridique pour une question prioritaire de
        constitutionnalité plaidée devant le Conseil constitutionnel sur la
        conformité à la Constitution des conditions d’exercice de certaines
        activités artisanales (loi du 5 juillet 1996 relative au développement
        et à la promotion du commerce et de l’artisanat).
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>
          En droit de la fonction publique
        </span>{' '}
        :
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Centre hospitalier</span> :
        assistance et représentation en justice devant le juge du
        référé-suspension et le juge du fond pour défendre la légalité de la
        décision d’un directeur de licencier un agent contractuel occupant le
        poste de directeur adjoint ;
      </div>
    );
    column2 = (
      <div>
        • <span style={{ fontWeight: '500' }}>Commune</span> : assistance et
        représentation en justice pour défendre la légalité d’une délibération
        du conseil municipal supprimant le poste de conservateur des
        bibliothèques ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Fonctionnaires</span> :
        assistance et représentation dans le cadre de procédures disciplinaires,
        puis devant le juge administratif pour contester les sanctions.
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>En droit public de l’énergie</span>
         :
        <br />
        <br />• Assistance d’une entreprise de transport de gaz naturel dans le
        cadre d’un litige concernant l’exécution d’un contrat d’acheminement ;
        <br />
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>En droit de l’expropriation</span> :
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>Communauté d’agglomération</span> :
        assistance, dans le cadre de la réalisation d’une ligne de tramway, pour
        le suivi des enquêtes publiques préalables aux déclarations d’utilité
        publique (DUP) complémentaires, des arrêtés de cessibilité ;
        représentation en justice pour la défense des arrêtés de cessibilité et
        de DUP ; représentation dans les procédures judiciaires de fixation
        d’indemnisation ;
        <br />
        <br />•{' '}
        <span style={{ fontWeight: '500' }}>
          Régie municipale de distribution d’eau
        </span>
         : assistance et représentation en justice pour la fixation des
        indemnités dues à des propriétaires et exploitants au titre de
        servitudes de protection d’une source d’eau potable ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Particulier</span> :
        assistance et représentation en justice dans le cadre du recours contre
        une déclaration d’utilité publique ;
        <br />
        <br />• <span style={{ fontWeight: '500' }}>Particulier</span> :
        assistance et représentation en justice pour la fixation des indemnités
        d’expropriation.
      </div>
    );
  }

  return (
    <div>
      <Banner lang={lang} />

      <div
        id=""
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 90px 0',
          backgroundImage: `url(${bleu})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
      >
        <div className="container">
          <div
            onClick={() => history.goBack()}
            style={{
              cursor: 'pointer',
              padding: '10px',
              position: 'absolute',
              left: '30px',
              top: '-9px',
            }}
          >
            <img src={back} alt="back" className="pictosBack" />
          </div>
          <div className="sectionTitle">{title}</div>
          <div className="lineTitle" />

          <div
            className="columns custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '0',
              fontSize: '12px',
              lineHeight: '21px',
            }}
          >
            <div
              className="column col"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              {column1}
            </div>

            <div
              id="col2"
              className="column col2"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                paddingBottom: '0px',
              }}
            >
              {column2}
            </div>
          </div>
        </div>
      </div>

      <ContactSection lang={lang} />
    </div>
  );
};

export default Expertises;
