import { useState, useEffect } from 'react'
import * as firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyAHJiQEGoxWF1y_axv5srO31xHJfnnePNY",
  authDomain: "mrc-avocats.firebaseapp.com",
  databaseURL: "https://mrc-avocats.firebaseio.com",
  projectId: "mrc-avocats",
  storageBucket: "mrc-avocats.appspot.com",
  messagingSenderId: "873506159231",
  appId: "1:873506159231:web:3013ce02ee37a62a185d4a"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth()

//////////////////////////////  FILES //////////////////////////////////////

export const uploadFile = (file, path = "") => new Promise((resolve) => {
  const storageRef = storage.ref()
  var uploadTask = storageRef.child(path + file.name).put(file);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          // console.log('Upload is paused');
          break;
        case 'running':
          // console.log('Upload is running');
          break;
      }
    }, (error) => {
      resolve(error)
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, () => {
      resolve(true)
      // Upload completed successfully, now we can get the download URL
      // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //   console.log('File available at', downloadURL);
      // });
    });
})

export const deleteFile = ({ path = "", name }) => new Promise((resolve) => {
  console.log(path, name)
  const storageRef = storage.ref()
  // Create a reference to the file to delete
  var desertRef = storageRef.child(path + name);

  // Delete the file
  desertRef.delete()
    .then(() => {
      // File deleted successfully
      resolve(true)
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      resolve(error)
    });
})

export const deleteFileBis = async (path, name) => {
  const storageRef = storage.ref()
  const desertRef = storageRef.child(path + name);

  // Delete the file
  try {
    await desertRef.delete()
  } catch (e) {
    console.error(e);
    return e
  } finally {
    console.log('File deleted successfully');
    return
  }
}

export const useFilesOnce = (path = '') => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])

  const files_ = []
  const refetchFiles = () => {
    const storageRef = storage.ref()
    // Create a reference under which you want to list
    var listRef = storageRef.child(path);

    // Find all the prefixes and items.
    listRef.listAll().then((res) => {
      if (res.items.length === 0) setLoading(false)
      // All the items under listRef.
      res.items.forEach((itemRef, index) => {
        itemRef.getDownloadURL().then((url) => {
          // FIXME: id
          files_.push({ id: url, link: url, name: itemRef.name })
          if (files_.length === res.items.length) {
            setFiles(files_)
            setLoading(false)
          }
        })
      });
    }).catch((error) => {
      setError(error)
    });
  }
  useEffect(() => {
    refetchFiles()
  }, [])

  return { error, loading, files, refetchFiles }
}


///////////////////////////// DOCUMENTS ///////////////////////////////////////
export const useDocumentOnce = (collection, id) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [document, setDocument] = useState([])

  useEffect(
    () => {
      if (!id || !collection) return
      db.collection(collection).doc(id).get().then((doc) => {
        if (doc.exists) {
          setLoading(false)
          setDocument(doc.data())
        } else {
          // doc.data() will be undefined in this case
          setError("No such document!");
        }
      }).catch((error) => {
        setError("Error getting document:", error);
      });
    },
    []
  )

  return { error, loading, document }
}

export const useDocuments = (collection) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState([])

  useEffect(
    () => {
      const unsubscribe = db
        .collection(collection)
        .orderBy("order_stamp", "desc")
        .onSnapshot(snapshot => {
          const documents = []
          snapshot.forEach(doc => {
            documents.push({ ...doc.data(), id: doc.id })
          })
          setLoading(false)
          setDocuments(documents)
        }, err => {
          console.log(err)
          setError(err)
        })

      return () => unsubscribe()
    },
    []
  )

  return { error, loading, documents }
}

export const createDocument = (collection, document) => new Promise((resolve) => {
  db.collection(collection).add({ ...document, order_stamp: new Date().getTime() - 1577833200000 })
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
      resolve(true)
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
})

export const updateDocument = (collection, document, id) => new Promise((resolve) => {
  db.collection(collection).doc(id).set(document, { merge: true })
    .then(() => {
      console.log("Document successfully written!");
      resolve(true)
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
})

export const deleteDocument = ({ collection, id }) => new Promise((resolve) => {
  db.collection(collection).doc(id).delete()
    .then(() => {
      console.log("Document successfully deleted!");
      resolve(true)
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
})

///////////////////////////// AUTH ///////////////////////////////////

export const login = (email, password) => new Promise((resolve) => {
  auth.signInWithEmailAndPassword(email, password)
    .then((user) => {
      resolve(user.user.uid);
    })
    .catch((error) => {
      resolve('error', error);
    });
});

export const logout = () => new Promise((resolve) => {
  auth.signOut()
    .then(() => {
      resolve(true);
    })
    .catch((error) => {
      resolve('error', error);
    });
});

export const useAuthState = () => {
  const [state, setState] = useState(() => {
    const user = auth.currentUser
    return { initializing: !user, user, }
  })

  const onChange = (user) => {
    setState({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}