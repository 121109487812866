import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDocumentOnce } from '../Admin/firebase';

import back from '../../static/back.svg';

import bleu from '../../static/bleu3_optim.jpg';
import ContactSection from '../../containers/ContactSection';
import Banner from '../../layouts/Banner';

const blue = '122342';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Article = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  const { error, loading, document } = useDocumentOnce('news', id);

  let history = useHistory();
  let lang = useQuery().get('lang');

  if (loading) return null;

  return (
    <>
      <Banner lang={lang} />

      <div
        id=""
        className="section"
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '40px 0 90px 0',
          backgroundImage: `url(${bleu})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
      >
        <div className="container">
          <div
            onClick={() => history.goBack()}
            style={{
              cursor: 'pointer',
              padding: '10px',
              position: 'absolute',
              left: '30px',
              top: '-9px',
            }}
          >
            <img src={back} alt="back" className="pictosBack" />
          </div>
          <div className="sectionTitle">{document['content title']}</div>
          <div className="lineTitle" />

          <div
            className="columns custom_justify"
            style={{
              margin: '60px 0 0 0',
              padding: '0',
              fontSize: '12px',
              lineHeight: '21px',
            }}
          >
            <div className="column is-2"></div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  document.content &&
                  document.content.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
              className="column is-8"
              style={{
                backgroundColor: '',
                fontSize: '12.5px',
                margin: '0',
                padding: '0px 40px',
              }}
            ></div>
            <div className="column is-2"></div>
          </div>
        </div>
      </div>

      <ContactSection lang={lang} />
    </>
  );
};

export default Article;
