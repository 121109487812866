import React from 'react'
import Article from './Article'
import News from './News'

import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

const Router = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`/News/:id`}>
        <Article />
      </Route>
      <Route exact path={`/News`}>
        <News />
      </Route>
    </Switch>
  )
}

export default Router