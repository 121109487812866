import React, { useState, useEffect } from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import back from '../../static/back.svg'
import './Carousel.css'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDocuments } from '../Admin/firebase'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

const Carousel = () => {
  let lang = useQuery().get("lang");
  const { error, loading, documents } = useDocuments('news')

  const galleryItems = (documents) => {
    return documents
      .filter(d => d.status === 'published' && d["display on front page"] === "true")
      .map((d, i) => <Item className="item" document={d} lang={lang}></Item>)
  }

  useEffect(() => {
    if (!documents) return
    setState({ ...state, galleryItems: galleryItems(documents) })
  }, [documents])

  const [state, setState] = useState({
    currentIndex: 0,
    itemsInSlide: window.innerWidth >= 768 ? 3 : 1,
    responsive: { 0: { items: 1 }, 768: { items: 3 } },
    galleryItems: [],
  })


  const slidePrevPage = () => {
    const currentIndex = state.currentIndex - state.itemsInSlide
    setState({ ...state, currentIndex })
  }

  const slideNextPage = () => {
    const {
      itemsInSlide,
      galleryItems: { length },
    } = state
    let currentIndex = state.currentIndex + state.itemsInSlide
    if (currentIndex > length) currentIndex = length

    setState({ ...state, currentIndex })
  }

  const handleOnSlideChange = (event) => {
    const { itemsInSlide, item } = event
    setState({ ...state, itemsInSlide, currentIndex: item })
  }

  if (loading) return null

  return (
    <div style={{ marginTop: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
      <div onClick={() => slidePrevPage()} style={{ width: '8px', cursor: 'pointer' }}>
        <img src={back} alt='back' className="pictosBack" />
      </div>
      <div style={{ width: 'calc(100% - 16px)', height: '100%', position: 'relative' }}>
        <AliceCarousel
          items={state.galleryItems}
          slideToIndex={state.currentIndex}
          responsive={state.responsive}
          // onInitialized={(e) => handleOnSlideChange(e)}
          onSlideChanged={(e) => handleOnSlideChange(e)}
          onResized={(e) => handleOnSlideChange(e)}
          dotsDisabled
          buttonsDisabled
        />
        <div style={{ pointerEvents: 'none', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, borderLeft: 'white 3px solid', borderRight: 'white 3px solid' }} ></div>
      </div>
      <div onClick={() => slideNextPage()} style={{ width: '8px', cursor: 'pointer' }}>
        <img src={back} alt='back' className="pictosBack" style={{ transform: 'rotate(180deg)' }} />
      </div>
    </div>
  )

}

export default Carousel


export const Item = ({ lang, document }) => {
  let history = useHistory();

  return (
    <div
      onClick={() => {
        if (document['link type'] === 'none') return
        if (document['link type'] === 'external') return openInNewTab(document['external link url'])
        history.push(lang ? `/News/${document.id}?lang=${lang}` : `/News/${document.id}`)
      }}
      className={document['link type'] === 'none' ? "" : "article"}
      style={{
        fontSize: '13px',
        marginLeft: "-1px",
        lineHeight: '21px',
        fontFamily: "Montserrat",
        textAlign: 'left',
        fontWeight: '500',
        letterSpacing: '0.5px',
        padding: '0 40px',
        borderLeft: '1px #F0F0F0 solid',
        borderRight: '1px #F0F0F0 solid'
      }}
    >
      <div className="articleTitle" style={{ marginBottom: '25px' }}>{document.category}</div>
      <div style={{ fontWeight: '300', fontSize: "11.5px", lineHeight: "19px", textAlign: "left", marginBottom: '15px' }}>{document.title}</div>
      <div style={{ fontWeight: '300', fontSize: "11.5px", lineHeight: "19px", textAlign: "left", fontStyle: 'italic' }}>{document.reference}</div>
    </div >
  )
}