import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';

import './Navbar.css';
import logo from '../static/PA_logo.jpg';

import { useScrollPosition } from './useScrollPosition';
import { useWindowSize } from './useWindowSize';

// FIXME: remove jquery
import $ from 'jquery';

const blue = 122342;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Navbar = () => {
  const lang = useQuery().get('lang');
  let { pathname, hash, search } = useLocation();
  let history = useHistory();

  const [headerHeight, setHeaderHeight] = useState(100);
  const [originalPos, setOriginalPos] = useState(0);

  const bannerHeight = 53;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const { y: yPos } = currPos;
      if (-yPos <= originalPos - headerHeight && size.width > 768) {
        $('#banner2').css({ visibility: 'hidden' });
        $('#banner').css({ visibility: 'visible' });
      }
      if (-yPos > originalPos - headerHeight && size.width > 768) {
        $('#banner2').css({ visibility: 'visible' });
        $('#banner').css({ visibility: 'hidden' });
      }
    },
    [headerHeight, originalPos]
  );

  const size = useWindowSize();

  useEffect(() => {
    if (!hash || pathname !== '/') return;
    setTimeout(() => {
      scroll(hash, true);
      history.replace(pathname + search);
    }, 200); // time to mount page
  }, [pathname]);

  useEffect(() => {
    setHeaderHeight(size.width <= 768 ? 124 : 100);
  }, [size]);

  useEffect(() => {
    $('#image').css('height', size.height - headerHeight - bannerHeight);
    setOriginalPos($('#banner').offset() ? $('#banner').offset().top : 0);
  }, [size, headerHeight, pathname]);

  const [isScrolling, setIsScrolling] = useState(false);

  const scroll = (id, fast = false) => {
    if (!isScrolling) {
      if (!$(id)) return;

      const pos =
        $(window).width() > 768
          ? $(id).offset().top - headerHeight - bannerHeight
          : $(id).offset().top - headerHeight;

      if (fast) {
        $('html,body').scrollTop(pos);
        return;
      }
      setIsScrolling(true);
      $('html,body')
        .stop()
        .animate(
          {
            scrollTop: pos,
          },
          'slow',
          () => {
            setTimeout(() => {
              setIsScrolling(false);
            }, 100);
          }
        );
    }
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: 'white',
          minHeight: '100px',
          position: 'fixed',
          width: '100%',
          zIndex: '214748364',
          padding: '25px 0 0px 0',
        }}
      >
        <div className="section" style={{ padding: '0' }}>
          <div className="container">
            <div
              className="columns"
              style={{ margin: '0 0 0 0', padding: '0' }}
            >
              <div
                className="column"
                style={{ margin: '5px 0 20px 0', padding: '0' }}
              >
                <div
                  onClick={() => {
                    pathname === '/'
                      ? $(window).scrollTop(0)
                      : history.push(lang ? `/?lang=${lang}` : '/');
                  }}
                >
                  <div className="logo">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              </div>

              <div
                className="column menu"
                style={{
                  padding: '0',
                  color: 'black',
                  fontSize: '13px',
                  letterSpacing: '0.8px',
                  fontWeight: '500',
                  display: 'flex',
                }}
              >
                <div
                  className="item"
                  // style={{ margin: '0 8px 0 0px' }}
                  onClick={() => {
                    pathname === '/'
                      ? scroll('#about')
                      : history.push(lang ? `/?lang=${lang}#about` : '/#about');
                  }}
                >
                  {lang === 'en' ? 'About us' : 'Cabinet'}
                </div>
                <div
                  className="item"
                  // style={{ margin: '0 8px 0 8px' }}
                  onClick={() => {
                    pathname === '/'
                      ? scroll('#expertises')
                      : history.push(
                          lang ? `/?lang=${lang}#expertises` : '/#expertises'
                        );
                  }}
                >
                  {lang === 'en' ? 'Expertise' : 'Expertise'}
                </div>
                <div
                  className="item"
                  // style={{ margin: '0 8px 0 8px' }}
                  onClick={() => {
                    pathname === '/'
                      ? scroll('#team')
                      : history.push(lang ? `/?lang=${lang}#team` : '/#team');
                  }}
                >
                  {lang === 'en' ? 'Team' : 'Équipe'}
                </div>
                <div
                  className="item"
                  // style={{ margin: '0 8px 0 8px' }}
                >
                  <Link to={lang ? `/News?lang=${lang}` : '/News'}>
                    {lang === 'en' ? 'News' : 'Actualités'}
                  </Link>
                </div>
                <div
                  className="item"
                  // style={{ margin: '0 8px 0 8px' }}
                  onClick={() => {
                    pathname === '/'
                      ? scroll('#contact')
                      : history.push(
                          lang ? `/?lang=${lang}#contact` : '/#contact'
                        );
                  }}
                >
                  {lang === 'en' ? 'Contact' : 'Contact'}
                </div>
                <div
                  className="item itemLang"
                  style={{
                    // marginLeft: '8px',
                    height: '20px',
                  }}
                >
                  <span
                    style={{}}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.replace(`${pathname}?lang=en`)}
                  >
                    En
                  </span>
                  &nbsp;|&nbsp;
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.replace(`${pathname}?lang=fr`)}
                  >
                    Fr
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="banner2"
        className="banner2"
        style={{
          position: 'fixed',
          width: '100%',
          height: '53px',
          top: `${headerHeight}px`,
          zIndex: '214748364',
          backgroundColor: `#${blue}`,
          color: 'white',
          padding: '16px 0 19px 0',
          letterSpacing: '0.8px',
          textAlign: 'center',
          fontWeight: '500',
        }}
      >
        {lang === 'en'
          ? 'Regulatory and Administrative Law'
          : 'Conseil et contentieux en droit public'}
      </div>
    </div>
  );
};

export default Navbar;
