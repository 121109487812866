import React from 'react';

import './Navbar.css';

import concorde from '../static/concorde4_optim.jpg';
import marker from '../static/marker.svg';
import address from '../static/address.svg';
import phone from '../static/phone.svg';

import { Link } from 'react-router-dom';

const ContactSection = ({ lang }) => {
  return (
    <div>
      <div
        id="contact"
        className="section"
        style={{
          padding: '40px 0 90px 0',
          color: 'black',
          backgroundImage: `url(${concorde})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="sectionTitle">Contact</div>
          <div className="lineTitle" />
          <center>
            <div
              style={{
                margin: '80px 0 2px 0',
                fontWeight: '500',
                letterSpacing: '0.8px',
                fontSize: '12px',
              }}
            >
              {lang === 'en'
                ? 'Lawyers registered with the Paris Bar'
                : 'Avocats au barreau de Paris'}{' '}
            </div>
            <div
              style={{
                margin: '0px 0 0px 0',
                fontWeight: '400',
                letterSpacing: '2px',
                fontSize: '12px',
              }}
            >
              A.A.R.P.I.
            </div>
          </center>
          <div
            className="columns custom_justify"
            style={{
              margin: '0px 0 0 0',
              padding: '0',
              fontSize: '11px',
              lineHeight: '21px',
            }}
          >
            <div
              className="column col"
              style={{ paddingTop: '50px', paddingBottom: '0px' }}
            >
              <center>
                <img src={address} alt="" className="pictos" />
                <br />
                <br />
                49 rue Saint-Roch <br />
                75001 Paris
                <br />
                <br />
                {/* <a href="mailto:contact@pergame-avocats.com">contact@pergame-avocats.com</a> */}
                Email : contact@pergame-avocats.com
                <br />
                <br />
                Palais : P 0301
              </center>
            </div>
            <div
              className="column col"
              style={{ paddingTop: '50px', paddingBottom: '0px' }}
            >
              <center>
                <img src={marker} alt="" className="pictos" />
                <br />
                <br />
                <a
                  href="https://goo.gl/maps/QybyB2XVRWs6a4hk9"
                  target="_blank"
                  style={{
                    textDecoration: '',
                    fontWeight: '500',
                    fontSize: '11px',
                  }}
                >
                  {lang === 'en' ? 'How to find us' : "Plan d'accès"}
                  <br />
                  <br />
                </a>
                {lang !== 'en' ? (
                  <span>
                    Métro : Pyramides (lignes 7, 14) / Tuileries (ligne 1)
                    <br />
                    <br />
                    Parking : INDIGO Marché Saint-Honoré <br />
                    (39 place du marché Saint-Honoré)
                  </span>
                ) : (
                  <span>
                    Metro: Pyramides station (7 and 14 lines) / Tuileries
                    station (1 line)
                    <br />
                    <br />
                    Parking : INDIGO Marché Saint-Honoré <br />
                    (39 place du marché Saint-Honoré)
                  </span>
                )}
              </center>
            </div>
            <div
              className="column col"
              style={{ paddingTop: '50px', paddingBottom: '0px' }}
            >
              <center>
                <img src={phone} alt="" className="pictos" />
                <br />
                <br />
                Tel :{' '}
                <a href="tel:+33 (0)1 42 68 26 02">+33 (0)1 42 68 26 02</a>
                <br />
                <br />
                Fax : + 33 (0)9 70 06 63 44
              </center>
            </div>
          </div>
        </div>
      </div>

      <Link to={'/Legal'}>
        <div
          className="section"
          style={{
            backgroundColor: '#122342',
            color: 'white',
            padding: '10px 0 10px 0',
            fontSize: '11px',
            lineHeight: '9px',
            letterSpacing: '0.8px',
            textAlign: 'center',
            fontWeight: '500',
          }}
        >
          <div className="container">Mentions légales</div>
        </div>
      </Link>
    </div>
  );
};

export default ContactSection;
