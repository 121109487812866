
import React, { Suspense } from 'react';

import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import CoreLayout from './layouts/CoreLayout'
import Home from './routes/Home'
import Lawyers from './routes/Lawyers'
import Expertises from './routes/Expertises'
import Legal from './routes/Legal'
import News from './routes/News'

import 'bulma/css/bulma.css';
import './app.css'

const Admin = React.lazy(() => import('./routes/Admin'));


const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <CoreLayout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/legal">
            <Legal />
          </Route>
          <Route path="/admin">
            <Suspense fallback={<div>Loading...</div>}>
              <Admin />
            </Suspense>
          </Route>
          <Route path="/lawyers/:id">
            <Lawyers />
          </Route>
          <Route path="/expertises/:id" >
            <Expertises />
          </Route>
          <Route path="/news" >
            <News />
          </Route>
        </Switch>
      </CoreLayout>
    </Router>
  );
}

export default App