import React from 'react'
import './Banner.css'
const blue = 122342

const Banner = ({ lang }) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "53px" }}>
      <div id='banner' className="banner" style={{ backgroundColor: `#${blue}`, color: "white", padding: "16px 0 19px 0", letterSpacing: "0.8px", textAlign: "center", fontWeight: "500" }}>
        {lang === 'en' ? "Regulatory and Administrative Law" : "Conseil et contentieux en droit public"}
      </div>
    </div>
  )
}

export default Banner